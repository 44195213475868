import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import sections from './slices/sections';

const reducer = combineReducers({
  sections,
});

export type AppState = ReturnType<typeof reducer>;
export const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;

export type AppThunkApiConfig = {
  state: AppState;
  dispatch: AppDispatch;
};

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
