import { ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { getErrorLink } from './getErrorLink';
import { getHttpLinks } from './getHttpLinks';
import { getWsLinks } from './getWsLinks';
import { retryLink } from './retryLink';

const isSubscription = ({ query }: any) => {
  const def = getMainDefinition(query);
  return def.kind === 'OperationDefinition' && def.operation === 'subscription';
};

export const getLink = (getToken: () => Promise<string>) => {
  const errorLink = getErrorLink(getToken);
  const httpLinks = getHttpLinks(getToken);
  const wsLinks = getWsLinks(getToken);
  const httpLink = ApolloLink.from([retryLink, errorLink, ...httpLinks]);
  const wsLink = ApolloLink.from([retryLink, errorLink, ...wsLinks]);
  return process.browser ? split(isSubscription, wsLink, httpLink) : httpLink;
};
