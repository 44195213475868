export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    IOEmbedData: ['OEmbedLinkData', 'OEmbedPhotoData', 'OEmbedRichData', 'OEmbedVideoData'],
  },
};
export default result;
