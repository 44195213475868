import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isMonthEnding, isWeekEnding } from '../../utils/utilFns';

export type Filter = 'activity' | 'experience' | 'time';

export type FilterControlsOpen = {
  [key in Filter]: boolean;
};

export interface ExploreFiltersState {
  activity: boolean;
  experience: boolean;
  time: boolean;
  groupTime: boolean;
  emptyState: boolean;
  filtersOpen: FilterControlsOpen;
  isMonthEnding: boolean;
  isWeekEnding: boolean;
  isOpen: boolean;
}

const initialState: ExploreFiltersState = {
  activity: false,
  experience: false,
  time: false,
  groupTime: false,
  emptyState: true,
  filtersOpen: {
    activity: false,
    experience: false,
    time: false,
  },
  isMonthEnding: isMonthEnding,
  isWeekEnding: isWeekEnding,
  isOpen: false,
};

export const slice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<ExploreFiltersState>>) => ({
      ...state,
      ...action.payload,
    }),
    setFilterOpen: (state, action: PayloadAction<{ filter: Filter; open: boolean }>) => {
      Object.keys(state.filtersOpen).forEach((key: string) => {
        state.filtersOpen[key as Filter] = false;
      });
      state.filtersOpen[action.payload.filter] = action.payload.open;
    },
    toggleFilterOpen: (state, action: PayloadAction<Filter>) => {
      const newState = !state.filtersOpen[action.payload];
      Object.keys(state.filtersOpen).forEach((key: string) => {
        state.filtersOpen[key as Filter] = false;
      });
      state.filtersOpen[action.payload] = newState;
    },
    closeFilters: state => {
      Object.keys(state.filtersOpen).forEach((key: string) => {
        state.filtersOpen[key as Filter] = false;
      });
    },
  },
});

export const actions = {
  ...slice.actions,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  slice,
  actions,
};
