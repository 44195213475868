'use client';
import { Alert, AlertColor } from '@mui/material';
import { createContext, useCallback, useState } from 'react';
import { SnackbarNotification } from '../../components/snackbar-notification/snackbar-notification';

export type SnackbarMessage = {
  message?: string;
  color?: AlertColor;
};

export type SnackbarContextProps = {
  setSnackbar: (options: SnackbarMessage) => void;
};

export const SnackbarContext = createContext<SnackbarContextProps>({
  setSnackbar: () => {
    // empty
  },
});

type SnackbarProviderProps = {
  children?: React.ReactElement;
};

type SnackbarState = SnackbarMessage & { open: boolean };

export const SnackbarProvider = ({ children }: SnackbarProviderProps): React.ReactElement => {
  const [message, setMessage] = useState<SnackbarState | null>(null);
  const handleCloseMessage = useCallback(() => {
    setMessage(oldMessage => ({ ...oldMessage, open: false }));
  }, [setMessage]);

  const handleSetMessage = useCallback(
    (message: any) => {
      setMessage({ ...message, open: true });
    },
    [setMessage],
  );

  return (
    <>
      <SnackbarNotification open={message?.open} onClose={handleCloseMessage}>
        <>
          {message?.message && (
            <Alert onClose={handleCloseMessage} severity={message?.color || 'success'}>
              {message?.message}
            </Alert>
          )}
        </>
      </SnackbarNotification>
      <SnackbarContext.Provider
        value={{
          setSnackbar: handleSetMessage,
        }}
      >
        {children}
      </SnackbarContext.Provider>
    </>
  );
};
