'use client';

import { config } from '@/config/environment';
import { useLoadScript } from '@react-google-maps/api';
import { ReactNode, useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { AnalyticsScript } from '../../components/analytics/analytics';
import PrimaryLayout from '../../components/layout/primary-layout/primary-layout';
import { VideoAskProvider } from '../../components/video-ask/providers/video-ask-provider/video-ask-provider';
import { store } from '../../store';
import { hello } from '../../utils/hello';
import { ApolloProvider } from '../apollo-provider/apollo-provider';
import { AuthProvider } from '../auth-provider/auth-provider';
import { DatePickerProvider } from '../date-picker-provider/date-picker-provider';
import { FancyboxProvider } from '../fancybox-provider/fancybox-provider';
import { FeaturesProvider } from '../features-provider/features-provider';
import { IdentityProvider } from '../identity-provider/identity-provider';
import { SnackbarProvider } from '../snackbar-provider/snackbar-provider';
import { ThemeProvider } from '../../app/common/providers/theme-provider/theme-provider';

interface RootProps {
  children: ReactNode;
}

export const RootProvider = ({ children }: RootProps): React.ReactElement => {
  useEffect(() => {
    hello();
  }, []);
  useLoadScript({
    googleMapsApiKey: config.googleApiKey!,
  });
  return (
    <>
      <AnalyticsScript />
      <AuthProvider>
        <ApolloProvider>
          <IdentityProvider>
            <ReduxProvider store={store}>
              <VideoAskProvider>
                <FancyboxProvider>
                  <ThemeProvider>
                    <SnackbarProvider>
                      <DatePickerProvider>
                        <FeaturesProvider>
                          <PrimaryLayout>{children}</PrimaryLayout>
                        </FeaturesProvider>
                      </DatePickerProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </FancyboxProvider>
              </VideoAskProvider>
            </ReduxProvider>
          </IdentityProvider>
        </ApolloProvider>
      </AuthProvider>
    </>
  );
};

export { RootProvider as Root };
