import { onError } from '@apollo/client/link/error';
import Observable from 'zen-observable';
import { config } from '../../environment';
import { ApiError } from '../types';

export const getErrorLink = (getToken: any) => {
  return onError(
    ({
      graphQLErrors,
      networkError,
      operation,
      forward,
      //  response
    }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (config.apolloLogErrors) {
            const { message, locations, path } = err;
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          }

          switch (err.extensions?.code) {
            case ApiError.UNAUTHENTICATED: {
              return new Observable(observer => {
                getToken()
                  .then((token: any) => {
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        Authorization: `Bearer ${token}`,
                      },
                    }));
                  })
                  .then(() => {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };
                    forward(operation).subscribe(subscriber);
                  })
                  .catch((error: any) => {
                    observer.error(error);
                  });
              });
            }
          }
        }
      }

      if (networkError && config.apolloLogErrors) {
        console.log(`[Network error]: ${networkError}`);
      }

      // Stop error propagation to next Links
      // if (response) {
      //   response.errors = null;
      // }
    },
  );
};
