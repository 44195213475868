export const daysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
};

const date = new Date();

export const lastDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  daysInMonth(date.getMonth() + 1, date.getFullYear()),
);

export const isMonthEnding = lastDay.getDate() - 3 < date.getDate();
export const isWeekEnding = 7 - date.getDay() < 3;
