import { config } from '../config/environment';

export const hello = () => {
  if (!config.helloLog || !process.browser) {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(
    `%c
                   :---:.
                  :-: .:--.
                 .--     .--:        .::---------:.
                 :-.       .--:  .:--::..       .:--
                 --          .----:.              .--
                 --         .:-:.:--:.             --
                 --       .--:     .::----::::..   --
                .-:    .:--.              ...:::-----
               .:-:    :.                         :---.
           .:-----:                :              -- .--
        .--:.   :-.               :-             :-.   --
      .--:      :-.             .--:             --    .-:
     --:        --            .----:            :-.    :-:
   .--         .-:    .:------------            --   .:-:
   --          --        .-----------.         :-::---:.
  :-.         --.          :----------:.       --:..
  :-.        :-.            ---------:---:    :-.
   :-:.     :-.             .----.            --
    .:---:::-:              .--.             :-.
         .-----::.          :-              .-:
         .-:   .::--:.      .               --
         --        .:--.                   :-.
        .-:           .--.                .-:
        .-:             .--.             .--
         --               :-:           .--
         :-:                :-:        .--
          .--.               .--:     :-:
            :-:.              .---:::--.
              :--::.      ..:--:  ...
                 .::------::.
    `,
    'color: #FFCF31',
    `

    Want to work with us?
    https://www.curiko.ca/contact-us/
    `,
  );
};
