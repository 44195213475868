import React from 'react';
import useSWR from 'swr';
import { FeaturesContext } from './contexts/features-context';

export function FeaturesProvider({ children }: { children: React.ReactNode }) {
  const { data } = useSWR('/api/features', async url => fetch(url).then(res => res.json()));

  if (!data) {
    return null;
  }

  return <FeaturesContext.Provider value={data || null}>{children}</FeaturesContext.Provider>;
}
