import { FieldPolicy, Reference } from '@apollo/client';

export const aggregateFieldPolicy = <TNode = Reference>(
  keyArgs: FieldPolicy<TNode>['keyArgs'] = false,
): FieldPolicy<TNode[]> => ({
  keyArgs,
  merge(existing, incoming) {
    if (!existing) {
      existing = [];
    }

    if (!incoming || !incoming.length) {
      return existing;
    }

    return incoming;
  },
});
