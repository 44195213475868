import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { GlobalStyles } from '../../../../providers/global-styles/global-styles';
import { theme } from './theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps): React.ReactElement => {
  useEffect(() => {
    // Remove the server-side injected CSS from MaterialUI
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <MaterialThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>
        {/* <StylesProvider injectFirst> */}
        <>
          <GlobalStyles />
          {children}
        </>
        {/* </StylesProvider> */}
      </EmotionThemeProvider>
    </MaterialThemeProvider>
  );
};

export { ThemeProvider };
