import { useRouter } from 'next/navigation';
import { ReactNode, useCallback } from 'react';
import { AppState, Auth0Provider } from '../../auth';
import { sessionStorageCache } from '../../auth/session-storage-cache';
import { config } from '../../config/environment';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = (props: AuthProviderProps) => {
  const router = useRouter();

  const onRedirectCallback = useCallback(
    (appState?: AppState): void => {
      router.replace(appState?.returnTo || '/');
    },
    [router],
  );

  return (
    <Auth0Provider
      {...config.auth0}
      cache={sessionStorageCache}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      {...props}
    />
  );
};
