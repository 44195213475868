import { createContext } from 'react';

export interface Features {
  inPerson: boolean | 'v1';
  experience: boolean | 'v1' | 'v2';
  explore: boolean | 'v1' | 'v2';
  booking: boolean | 'v1' | 'v2' | undefined;
}

export const FeaturesContext = createContext<Features | null>(null);
