import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useAuth0 } from '../../auth';
import { getLink } from './links/getLink';
import { cache } from './cache';
import { useMemo } from 'react';

export const useClient = (): ApolloClient<NormalizedCacheObject> => {
  const { getAccessTokenSilently } = useAuth0();
  return useMemo(() => {
    const link = getLink(getAccessTokenSilently);
    return new ApolloClient({ link, cache });
  }, [getAccessTokenSilently]);
};
