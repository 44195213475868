import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

export const useAnalyticsPageTracking = () => {
  const pathname = usePathname() || '';
  const pathnameNormalized = `${pathname.replace(/\/$/, '')}/`;
  const prevPath = useRef<string>(pathnameNormalized);

  useEffect(() => {
    const pathnameNormalized = `${pathname.replace(/\/$/, '')}/`;
    if (prevPath.current !== pathnameNormalized) {
      prevPath.current = pathnameNormalized;
      (window as any).analytics.page({
        path: window.location.pathname,
        url: window.location.href,
      });
    }
  }, [pathname]);
};
