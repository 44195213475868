import { InMemoryCache } from '@apollo/client';
import {
  EventConnection,
  ExperienceConnection,
  FeedbackConnection,
  FileConnection,
  ImageConnection,
  MessageConnection,
  MessageThreadConnection,
  NotificationConnection,
} from '../../../generated/graphql';
import fragmentMatcher from '../../../generated/graphql/fragment-matcher';
import { aggregateFieldPolicy } from './aggregateFieldPolicy';
import { relayStylePagination } from './relayStylePagination';

const connKeys = ['sorting', 'filter'];

export const cache = new InMemoryCache({
  ...fragmentMatcher,
  typePolicies: {
    Query: {
      fields: {
        notifications: relayStylePagination<NotificationConnection>(connKeys),
        notificationAggregate: aggregateFieldPolicy(['filter']),
        feedbacks: relayStylePagination<FeedbackConnection>(connKeys),
        experiences: relayStylePagination<ExperienceConnection>(connKeys),
        images: relayStylePagination<ImageConnection>(connKeys),
        events: relayStylePagination<EventConnection>(connKeys),
        groupExperienceEvents: relayStylePagination<EventConnection>(connKeys),
        bookings: relayStylePagination<EventConnection>(connKeys),
        attendees: relayStylePagination<EventConnection>(connKeys),
        files: relayStylePagination<FileConnection>(connKeys),
        messageThreads: relayStylePagination<MessageThreadConnection>(connKeys),
        messages: relayStylePagination<MessageConnection>(connKeys),
      },
    },
    Experience: {
      fields: {
        content: {
          merge: true,
        },
      },
    },
  },
});
