import { Snackbar, SnackbarProps } from '@mui/material';
import React, { useCallback } from 'react';

export type SnackbarNotificationProps = {
  children?: React.ReactElement;
  open?: boolean;
  onClose?: () => void;
};

type OnClose = SnackbarProps['onClose'];
type OnCloseParams = Parameters<NonNullable<OnClose>>;

export const SnackbarNotification = ({
  open,
  onClose,
  children,
}: SnackbarNotificationProps): React.ReactElement => {
  const handleClose = useCallback(
    (event: OnCloseParams[0], reason?: OnCloseParams[1]) => {
      if (reason === 'clickaway') {
        return;
      }
      onClose?.();
    },
    [onClose],
  );
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div>{children}</div>
    </Snackbar>
  );
};
