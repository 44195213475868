import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { config } from '../../environment';

const abortController = new AbortController();

const httpLink = createUploadLink({
  uri: config.apiEndpoint,
  fetchOptions: {
    mode: 'cors',
    signal: abortController.signal,
  },
});

export const getHttpLinks = (getToken: any) => {
  const authLink = setContext(async (_, request) => {
    const { headers, ...others } = request;
    try {
      const token = await getToken();
      const authHeaders = token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {};

      return {
        ...others,
        headers: {
          ...headers,
          ...authHeaders,
        },
      };
    } catch (error) {
      return request;
    }
  });

  return [authLink, httpLink];
};
