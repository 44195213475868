import LogRocket from 'logrocket';
//@ts-ignore
import setupLogRocketReact from 'logrocket-react';
import { config } from '../config/environment';

if (process.browser && config.logRocketId && process.env.NEXT_PUBLIC_BUILD_ID) {
  LogRocket.init(config.logRocketId, {
    release: process.env.NEXT_PUBLIC_BUILD_ID,
  });
  setupLogRocketReact(LogRocket);
}
