import { ICache } from '@auth0/auth0-spa-js';

const AUTH0_CACHE_PREFIX = '@@auth0spajs@@';

export const sessionStorageCache: ICache = {
  get: (key: string) => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },

  set: (key: string, value: unknown) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove: (key: string) => {
    sessionStorage.removeItem(key);
  },

  allKeys: () => {
    return Object.keys(sessionStorage).filter(key => key.indexOf(AUTH0_CACHE_PREFIX) === 0);
  },
};
