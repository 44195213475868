import { ApolloProvider as ApolloProviderFromApollo } from '@apollo/client';
import { useClient } from '../../config/graphql/useClient';

interface ApolloProviderProps {
  children: React.ReactNode;
}

export const ApolloProvider = (props: ApolloProviderProps) => {
  return <ApolloProviderFromApollo client={useClient()} {...props} />;
};
